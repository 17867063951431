import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import Img from "gatsby-image";
import Fade from 'react-reveal/Fade'

const Bio = ({ data }) => (
    <Layout>
    <div className="fixed-top bg-light py-lg-3 text-center">
    <h1 className="d-none d-lg-inline-block mb-0 h2 lishan-xue">Lishan Xue</h1><br />
    <p className="d-none d-lg-inline-block fst-italic title">Pianist</p>
        <nav className="navbar navbar-expand-lg navbar-purple bg-light mx-3 mx-lg-none">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <h1 className="d-lg-none pl-2 h2 fw-bold mobile-title">Lishan Xue<br /><span className="fst-italic h6 fw-normal">Pianist</span></h1>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/">Home | <span className="text-muted">主页</span></a>
                    </li>
                    <li className="nav-item active">
                        <a className="nav-link" href="/bio">About | <span className="text-muted">个人</span> <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/news">News | <span className="text-muted">新闻</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/concerts">Concerts | <span className="text-muted">演出</span></a>
                    </li>
                    <li className="nav-item dropdown" id="media">
                        <a className="nav-link dropdown-toggle" href="/media" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Media | <span className="text-muted">媒体</span>
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="/media/#videos">Videos</a>
                        <a className="dropdown-item" href="/media/#photos">Photos</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/repertoire">Repertoire | <span className="text-muted">常备曲目</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/teaching">Teaching | <span className="text-muted">教学</span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact | <span className="text-muted">联系方式</span></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <div className="container pt-0 bio">
    <Fade>
    <Img fluid={data.photo.data.image.localFiles[0].childImageSharp.fluid} alt={data.photo.data.alt} className="mb-5" />
        <div className="row">
            <div className="col-12 col-lg-6">
                <div dangerouslySetInnerHTML={{
                __html: data.bio.data.bio.childMarkdownRemark.html,
                }} className="bio-text" />
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0 chinese">
                <div lang="zh-Hans" dangerouslySetInnerHTML={{
                __html: data.chinese.data.bio.childMarkdownRemark.html,
                }} />
                {/* <div className="mt-5 ml-5 text-right">
                    <a href={data.resume.data.pdf[0].url} target="_blank" rel="noopener noreferrer"><p className="btn btn-outline-dark mr-4">Résumé</p></a>
                    <a href={data.resume.data.pdf[0].url} target="_blank" rel="noopener noreferrer"><p className="btn btn-outline-dark">艺术简历</p></a>
                </div> */}
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-1"><a href={data.resume.data.perLink} target="_blank" rel="noopener noreferrer"><p className="btn btn-outline-dark">Résumé</p></a></div>
            <div className="col-1"><a href={data.resumecn.data.perLink} target="_blank" rel="noopener noreferrer"><p className="btn btn-outline-dark">艺术简历</p></a></div>
        </div>
    </Fade>
    </div>
    </Layout>
)
export default Bio;

export const query = graphql`
{
    photo: airtable(table: {eq: "Bio"}, data: {name: {eq: "photo"}}) {
        data {
            alt
            image {
                localFiles {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    bio: airtable(table: {eq: "Bio"}, data: {name: {eq: "bio"}}) {
        data {
            bio {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    chinese: airtable(table: {eq: "Bio"}, data: {name: {eq: "bio-cn"}}) {
        data {
            bio {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    resume: airtable(table: {eq: "Bio"}, data: {name: {eq: "resume"}}) {
        data {
            perLink
        }
    }

    resumecn: airtable(table: {eq: "Bio"}, data: {name: {eq: "resume-cn"}}) {
        data {
            perLink
        }
    }
}  
`;